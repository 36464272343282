import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Components/Assets/logo.png';
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa6";
import { REACT_API_URL } from '../config';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState('');
    const [visiblePassword, setVisiblePassword] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const validate = () => {
        let isValid = true;
        const errors = {};

        if (email.trim() === '') {
            errors.email = 'Email Address is required*';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email Address is invalid*';
            isValid = false;
        }

        if (password.trim() === '') {
            errors.password = 'Password is required*';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleLogIn = async (e) => {
        e.preventDefault();
        if (validate()) {
            setIsLoading(true); // Start loading state
            try {
                const response = await fetch(`${REACT_API_URL}/auth/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password,
                    }),
                });  

                if (response.ok) {
                    const data = await response.json();
                    localStorage.setItem('token', data.accessToken);

                    // Delay navigation to show "Logging in..."
                    setTimeout(() => {
                        setIsLoading(false);
                        navigate('/dashboard');
                    }, 1000);
                } else {
                    const errorData = await response.json();
                    setLoginError(errorData.message);
                    setIsLoading(false);
                }
            } catch (error) {
                setLoginError('An error occurred. Please try again.');
                console.error('Error during login:', error);
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="h-screen flex items-center overflow-hidden">
            <div className="w-fit flex flex-col items-center justify-center mx-auto gap-5 border-2 p-10 rounded-sm">
                {/* Logo */}
                <img className='h-[60px]' src={logo} alt="E-Livery" />
                {/* Page Title */}
                <h2 className='text-[18px] text-center font-[600] tracking-wide'>
                    LOGIN TO YOUR ACCOUNT <p className='text-[12px]'>(USER)</p>
                </h2>
                {/* Input Form */}
                <form onSubmit={handleLogIn} className='w-full flex flex-col justify-center items-center'>
                    <div>
                        <span className="flex gap-2">
                            <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Email Address</label>
                        </span>
                        <span className='flex flex-col h-14'>
                            <input
                                className='bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none'
                                type="email"
                                placeholder='Enter your Email Address'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <p className="text-red-500 text-[12px] font-medium">{errors.email}</p>}
                        </span>
                        <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Password</label>
                        <span className='flex flex-col h-14'>
                            <span className='flex items-center'>
                                <input
                                    className='bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none'
                                    type={visiblePassword ? "password" : "text"}
                                    placeholder='Enter your Password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span onClick={() => setVisiblePassword(!visiblePassword)}
                                    className='cursor-pointer ml-[-30px] rounded-md flex justify-center items-center'>
                                    {visiblePassword ? (<IoEyeOutline />) : (<FaRegEyeSlash />)}
                                </span>
                            </span>
                            {errors.password && <p className="text-red-500 text-[12px] font-medium">{errors.password}</p>}
                            {loginError && <p className="text-red-500 text-[12px] font-medium">{loginError}</p>}
                        </span>

                        <div className="lg:block md:flex flex justify-center w-full">
                            <Link to="/identify" className='text-[12px] text-[#3C4385] underline float-end my-1'>Forgot password?</Link>
                        </div>
                        <div className="lg:block md:flex flex justify-center w-full">
                            <Link to="/agentlogin" className='text-[12px] text-[#3C4385] text-center underline my-1'>LOGIN AS AN AGENT</Link>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-5 py-3 w-full">
                            <button
                                type="submit"
                                disabled={isLoading}
                                className='w-[250px] bg-[#00a3b1] text-white text-[12px] font-[500] rounded-md p-[6px]'
                            >
                                {isLoading ? 'Logging in...' : 'Login now'}
                            </button>
                        </div>
                        <div className="flex items-center justify-center">
                            <hr className="border-t border-black w-[33%]"></hr>
                            <span className="mx-2 text-black text-[14px]">OR</span>
                            <hr className="border-t border-black w-[33%]"></hr>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-5 py-3 w-full">
                            <Link to="/signup"><button className='w-[250px] border-2 text-[#00a3b1] text-[12px] font-[500] rounded-md p-[6px]'>Signup now</button></Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
